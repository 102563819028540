<template>
  <!-- Modal -->
  <div class="modal" id="pwdModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cambiar Contraseña</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Proveedor:</label>
              <p>{{ item.frm_proveedor }}</p>
            </div>
            <div class="form-group">
              <label>Usuario:</label>
              <p>{{ item.frm_user }}</p>
            </div>
            <div class="form-group">
              <label>Nueva contraseña (*):</label>
              <input type="password" class="form-control" v-model="item.frm_password_new">
            </div>
            <div class="form-group">
              <label>Confirmar:</label>
              <input type="password" class="form-control" v-model="item.frm_password_conf">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('change-pwd', item)">Cambiar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['item'],
  data() {
    return {

    }
  },
  created: function(){

  },
  mounted: function() {

  },
  methods: {

  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>
